// const menu = [
//   {
//     icon: "cart-fill",
//     text: "Dashboard",
//     link: "/",
//   },

//   {
//     icon: "package-fill",
//     text: "Category",
//     active: false,
//     subMenu: [
//       {
//         text: "Main Category",
//         link: "/main-category-list",
//       },

//       {
//         text: "Sub Category",
//         link: "/sub-category-list",
//       },

//       {
//         text: "Child Category",
//         link: "/child-category-list",
//       },
//     ],
//   },

//   {
//     icon: "cc-alt2-fill",
//     text: "Catalog",
//     active: false,
//     subMenu: [
//       {
//         text: "Attributes",
//         link: "/attr-table",
//       },

//       {
//         text: "Brands",
//         link: "/menu-table",
//       },
//       {
//         text: "Tags",
//         link: "/tags",
//       },
//     ],
//   },

//   {
//     icon: "card-view",
//     text: "Products",

//     subMenu: [
//       {
//         text: "Product List",
//         link: "/prod-list",
//       },
//       {
//         text: "Stock List",
//         link: "/stock-list",
//       },
//       {
//         text: "Reviews",
//         link: "/product-review",
//       },
//       {
//         text: "Q & A",
//         link: "/question-answer",
//       },
//       // {
//       //   text: "Reviews",
//       //   link: "/prod-Reviews",
//       // },
//       {
//         text: "Subscription",
//         link: "/prod-subscription",
//       },
//     ],
//   },

//   {
//     icon: "activity-round-fill",
//     text: "Coupon",
//     link: "/coupons",
//   },
//   {
//     icon: "offer-fill",
//     text: "Discount",
//     link: "/discount",
//   },

//   {
//     icon: "file-docs",
//     text: "Orders",
//     active: false,
//     subMenu: [
//       {
//         text: "Orders",
//         link: "/order-data",
//       },
//       {
//         text: "Carts",
//         link: "/cart-list",
//       },
//       {
//         text: "Cancellations",
//         link: "/cancel-list",
//       },
//       {
//         text: "Return",
//         link: "/return-list",
//       },
//       {
//         text: "Settings",
//         link: "/orders-setting",
//       },
//     ],
//   },

//   {
//     icon: "bag",
//     text: "Vendors",
//     active: false,
//     subMenu: [
//       {
//         text: "Vendor List",
//         link: "/merchant-list",
//       },
//       {
//         text: "Warehouse list",
//         link: "/warehouse",
//       },
//     ],
//   },

//   {
//     icon: "user-c",
//     text: "Customers",
//     link: "/customer-list",
//   },

//   {
//     icon: "users-fill",
//     text: "Admin",

//     active: false,
//     subMenu: [
//       {
//         text: "Staff ",
//         link: "/admin",
//       },

//       {
//         text: "User Roles",
//         link: "/roles",
//       },
//     ],
//   },

//   {
//     icon: "coin",
//     text: "Points",
//     active: false,
//     subMenu: [
//       {
//         text: "Redeem Points ",
//         link: "/redeem_points",
//       },
//       {
//         text: "Reward Points ",
//         link: "/Reward-Points",
//       },
//       {
//         text: "Settings",
//         link: "/points_settings",
//       },
//     ],
//   },

//   {
//     icon: "growth-fill",
//     text: "Appearance",
//     active: true,
//     subMenu: [
//       {
//         text: "Banners",
//         link: "/banners",
//       },
//       {
//         text: "Popular categories",
//         link: "/popular_categories",
//       },

//         {
//           text: "Featured brands",
//           link: "/featured_brands",
//         },
//       //   {
//       //     text: "Deals of the week",
//       //     link: "/deals_of_the_week",
//       //   },
//       //   {
//       //     text: "Everyday needs",
//       //     link: "/everyday_needs",
//       //   },
//       //   {
//       //     text: "New arrivals",
//       //     link: "/new_arrivals",
//       //   },
//       {
//         text: "Home Page Section",
//         link: "/home",
//       },

//     ],
//   },
//   {
//     icon: "book",
//     text: "Blogs",
//     link: "/blog",
//     active: true,
//   },

//   {
//     icon: "sign-dash-alt",
//     text: "Shipping",
//     link: "/shipping",
//   },
//   {
//     icon: "gift",
//     text: "Gift Offers",
//     link: "/Gift-offers",
//   },
//   {
//     icon: "",
//     text: "Invoice",
//     link: "/invoice_page",
//   },
//   {
//     icon: "",
//     text: "Credit Notes",
//     link: "/create_notes",
//   },
// ];
// export default menu;
