import React, { useState } from "react";
import { Card } from "reactstrap";
import axios from "axios";
import { API_URL, API_Order, token } from "../../../Api";
import { Badge, Button, FormGroup, Modal, ModalBody, ModalHeader, ModalFooter, Label } from "reactstrap";
import diapers from "../../../assets/images/jsTree/diapers.jpg";
import { ToastContainer, toast } from "react-toastify";
import nappycleaning from "../../../assets/images/jsTree/nappycleaning.jpg";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  BackTo,
  PreviewCard,
  ReactDataTable,
  Row,
  RSelect,
  Col,
} from "../../../components/Component";
import { useHistory } from "react-router";
import { useEffect } from "react";
const API_Image = `${API_URL}/Product_image`;
// const API_Preparing = `${API_URL}/admin/deliveryone/createtask`;
// const API_Cancel = `${API_URL}/admin/deliveryone/droptask`;
// const API_Drop = `${API_URL}/admin/deliveryone/dunzogetdata`;
const API_Preparing = `${API_URL}/admin/shipping/createdelivery`;
// const API_Cancel = `${API_URL}/admin/shipping/dropdelivery`;
const API_Status_Check = `${API_URL}/admin/shipping/deliverystatuscheck`;
// const API_Drop = `${API_URL}/admin/shipping/eshipgetdata`;
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};
const Cards = ({ currElement, location, Warehouse }) => {
  const {
    EAN,
    HSN,
    ProductImage,
    ProductWeight,
    RegularPrice,
    SKU,
    SalePrice,
    Shipping_type,
    Suborder_id,
    Warehouse_id,
    babyamore_discount,
    babyamore_sale_amt,
    company_name,
    createDt,
    email_address,
    order_no,
    order_status,
    orders_id,
    productname,
    product_id,
    qty,
    full_name,
    id,
    saleprice,
    shipping_company,
    sub_total,
    total,
    transaction_id,
    user_id,
    phone_number,
    Billingaddress,
    user_ip,
    vendor_id,
    modifyDt,
    shippedDt,
    deliverDt,
    returnDt,
    warehouse_name,
    vendor_sale_amt,
  } = currElement;

  const history = useHistory();
  const [values, setValues] = useState(Warehouse_id);
  const [isSPMethod, setIsSPMethod] = useState({ value: order_status, label: order_status });

  const handleChangewarehouse = async (e) => {
    setValues(e.target.value);
  };
  const handleChangeShip = async (event) => {
    setIsSPMethod(event);

    // const datas = await axios.put(`${API_Vendor}/${token}`, { isActive: state },config);
    // if (datas) {
    //   getData(token);
    // }
  };
  const handleSubmits = async () => {
    const re = /\s*(?:,|$)\s*/;
    // console.log(nameList, "Billingaddress");
    if (order_status == "New orders" || order_status == "New Orders") {
      let formData = new FormData();
      formData.append("Warehouse_id", values);
      await axios
        .put(`${API_Order}/wareupdate/${location.order_no}/${location.Suborder_id}/${product_id}`, formData, config)
        .then((res) => {
          toast.success("Status updated successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // setResMsg(res.data.msg, "resss");
        });
    }
    if (isSPMethod.value == "Preparing") {
      try {
        const nameList = Billingaddress.split(re);

        let datas = {
          order_id: order_no,
          store_name: "Babyamore",
          store_id: "",
          shopify_order_id: transaction_id,
          order_created_on: createDt,
          is_cod: true,
          shipment_value: saleprice,
          order_currency: "INR",
          cod_amount: saleprice,
          order_status: order_status,
          shipment_type: "Parcel",
          invoice_number: "",

          receiver_first_name: full_name,
          receiver_last_name: "",
          receiver_company_name: "",
          receiver_address: nameList ? nameList[1] : null,
          receiver_city: nameList ? nameList[2] : null,
          receiver_state: nameList ? nameList[4] : null,
          receiver_country: nameList ? nameList[5] : null,
          receiver_zipcode: nameList ? nameList[3] : null,
          receiver_landmark: "",
          receiver_gst_number: "",
          receiver_phone: phone_number,
          receiver_email: email_address,
          sender_first_name: "Baby",
          sender_last_name: "Amore",
          sender_company_name: "BabyAmore",
          sender_address: "Office No 2B, Apex Plaza, 2nd Floor No 3 Uthamar Gandhi,Anna salai,Nungambakkam",
          sender_city: "Chennai",
          sender_state: "Tamil Nadu",
          sender_country: "India",
          sender_zipcode: "600034",
          sender_landmark: "",
          sender_gst_number: "",
          sender_phone: "9876543210",
          sender_email: "babyamore@gmail.com",
          description: productname,
          items: [
            {
              quantity: qty,
              weight: {
                unit_of_measurement: "kg",
                value: ProductWeight,
              },
              dimensions: {
                unit_of_measurement: "cms",
                length: "1",
                width: "1",
                height: "1",
                irregular_parcel_girth: "1",
              },
              value: {
                currency: "INR",
                amount: total,
              },
              sku: SKU,
            },
          ],
          is_mps: true,
          parcels: [
            {
              reference_number: order_no,
              quantity: qty,
              weight: {
                unit_of_measurement: "kg",
                value: ProductWeight,
              },
              dimensions: {
                length: "1",
                width: "1",
                unit_of_measurement: "cm",
                height: "1",
              },
            },
          ],
        };
        let formData = new FormData();
        // formData.append("Warehouse_id", body);
        await axios.post(`${API_Preparing}`, datas, config).then((res) => {
          toast.success("Status updated successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // setResMsg(res.data.msg, "resss");
        });
      } catch (error) {
        console.log(error, "ooooooo");
      }
    }
    if (isSPMethod.value == "Cancelled") {
      try {
        let formdata = new FormData();
        formdata.append("order_id", order_no);
        formdata.append("sub_orderid", Suborder_id);

        let result = await axios
          .post(API_Drop, formdata, config)
          .then(async (res) => {
            if (res.data.list?.length > 0) {
              let { order_id, task_id, user_id, sub_orderid } = res.data.list[0];
              let body = {
                cancellation_reason: "Changed my mind",
                task_id: task_id,
                order_id: order_id,
                sub_orderid: sub_orderid,
                user_id: user_id,
              };
              setTimeout(async () => {
                await axios.post(`${API_Cancel}`, body, config).then((res) => {
                  toast.success("Cancel Status updated successfully", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  // setResMsg(res.data.msg, "resss");
                });
              }, 1000);
            } else {
              alert("task id not generate");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error, "ooooooo");
      }
    }

    let formDatas = new FormData();
    formDatas.append("status", isSPMethod.value);
    await axios.put(`${API_Order}/status/${id}`, formDatas, config).then((res) => {
      // setResMsg(res.data.msg, "resss");
      // history.push("/dashboard/order-data");
    });
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Card>
        <div className="card-inner">
          <div className="card-title-group mb-2">
            <div className="card-header w-100 mb-1">
              <h5 className="title">Order details</h5>
            </div>
          </div>

          {/*order start*/}

          <>
            <div className="row mb-5">
              <div className="col-md-12">
                <div className="shipdea">
                  <div className="row d-flex justify-content-between mb-2">
                    <div className="col-md-6">
                      {/* <h6>Order id : #BA001_01</h6> */}
                      <span class="d-block">Sub Order ID: {Suborder_id}</span>
                      <span class="d-block">Vendor : {company_name}</span>

                      <span>Warehouses : {warehouse_name}</span>
                      <span class="d-block mt-2 mb-1">
                        <img
                          className="imgcour"
                          src="https://uploads-ssl.webflow.com/5ef27cb65411b70949a151e9/5f17078fcfae300fc5b3d69c_download%20(16).png"
                        />
                      </span>
                    </div>

                    <div className="col-md-6 hh-grayBox pt45 pb20">
                      {order_status == "Cancel" ? (
                        <>
                          <div className="row">
                            <div className={"order-tracking completed"}>
                              <span className="is-complete" />
                              <p>
                                Ordered
                                <br />
                                <span>{new Date(createDt).toLocaleDateString()}</span>
                              </p>
                            </div>
                            <div
                              className={
                                order_status == "Shipped" ||
                                  order_status == "Completed" ||
                                  order_status == "RTO In-transit" ||
                                  order_status == "Cancel" ||
                                  order_status == "Lost (AWB)" ||
                                  order_status == "Return" ||
                                  order_status == "Returned" ||
                                  order_status == "Refunded"
                                  ? "order-tracking completed"
                                  : "order-tracking"
                              }
                            >
                              <span className="is-complete" />
                              <p>
                                Cancelled
                                <br />
                                <span>
                                  {order_status == "Cancel"
                                    ? new Date(modifyDt).toLocaleDateString()
                                    : modifyDt == null
                                      ? null
                                      : new Date(modifyDt).toLocaleDateString()}
                                </span>
                              </p>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {order_status == "Cancelled" || order_status == "Request For Cancel" ? (
                        <>
                          <div className="row justify-content-between">
                            <div className={"order-tracking completed"}>
                              <span className="is-complete" />
                              <p>
                                Ordered
                                <br />
                                <span>{new Date(createDt).toLocaleDateString()}</span>
                              </p>
                            </div>
                            <div
                              className={
                                order_status == "Shipped" ||
                                  order_status == "Completed" ||
                                  order_status == "RTO In-transit" ||
                                  order_status == "Request For Cancelled" ||
                                  order_status == "Lost (AWB)" ||
                                  order_status == "Return" ||
                                  order_status == "Returned" ||
                                  order_status == "Refunded" ||
                                  order_status == "Request For Cancel"
                                  ? "order-tracking completed"
                                  : "order-tracking"
                              }
                            >
                              <span className="is-complete" />
                              <p>
                                Shipped
                                <br />
                                <span>
                                  {order_status == "Request For Cancel"
                                    ? new Date(shippedDt).toLocaleDateString()
                                    : shippedDt == null
                                      ? null
                                      : new Date(shippedDt).toLocaleDateString()}
                                </span>
                              </p>
                            </div>
                            <div
                              className={
                                order_status == "Cancelled" || order_status == "Request For Cancel"
                                  ? "order-tracking completed"
                                  : "order-tracking"
                              }
                            >
                              <span className="is-complete" />
                              <p>
                                {order_status == "Cancelled"
                                  ? "Cancelled"
                                  : order_status == "Request For Cancel"
                                    ? "Request For Cancel"
                                    : null}
                                <br />
                                <span>{new Date(modifyDt).toLocaleDateString()}</span>
                              </p>
                            </div>
                          </div>
                        </>
                      ) : null}
                      {order_status == "Cancel" ||
                        order_status == "Cancelled" ||
                        order_status == "Request For Cancel" ? (
                        <>{null}</>
                      ) : (
                        <>
                          <div className="row justify-content-between">
                            <div className={"order-tracking completed"}>
                              <span className="is-complete" />
                              <p>
                                Ordered
                                <br />
                                <span>{new Date(createDt).toLocaleDateString()}</span>
                              </p>
                            </div>
                            <div
                              className={
                                order_status == "Shipped" ||
                                  order_status == "Completed" ||
                                  order_status == "RTO In-transit" ||
                                  order_status == "Request For Cancel" ||
                                  order_status == "Lost (AWB)" ||
                                  order_status == "Return" ||
                                  order_status == "Returned" ||
                                  order_status == "Refunded"
                                  ? "order-tracking completed"
                                  : "order-tracking"
                              }
                            >
                              <span className="is-complete" />
                              <p>
                                Shipped
                                <br />
                                <span>
                                  {order_status == "Shipped"
                                    ? new Date(shippedDt).toLocaleDateString()
                                    : shippedDt == null
                                      ? null
                                      : new Date(shippedDt).toLocaleDateString()}
                                </span>
                              </p>
                            </div>
                            <div
                              className={
                                order_status == "Completed" ||
                                  order_status == "Return" ||
                                  order_status == "Returned" ||
                                  order_status == "Refunded"
                                  ? "order-tracking completed"
                                  : "order-tracking"
                              }
                            >
                              <span className="is-complete" />
                              <p>
                                {order_status == "Completed"
                                  ? "Delivered"
                                  : order_status == "Return"
                                    ? "Return"
                                    : order_status == "Returned"
                                      ? "Returned"
                                      : order_status == "Refunded"
                                        ? "Refunded"
                                        : "Delivered"}
                                <br />
                                <span>
                                  {order_status == "Completed"
                                    ? new Date(deliverDt).toLocaleDateString()
                                    : order_status == "Return"
                                      ? null
                                      : order_status == "Returned"
                                        ? new Date(returnDt).toLocaleDateString()
                                        : order_status == "Refunded"
                                          ? new Date(modifyDt).toLocaleDateString()
                                          : null}
                                </span>
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {/* {order_status == "Return"?  <div className="text-center text-success mt-3">
                                        <h5>Your Return Request Submited Successfully</h5>
                                      </div>:null}
                                  {order_status == "Returned"?  <div className="text-center text-success mt-3">
                                        <h5>Your Order is Returned</h5>
                                      </div>:null}
                                  {order_status == "Refunded"?  <div className="text-center text-success mt-3">
                                        <h5>Your Order is Refunded</h5>
                                      </div>:null}
                                  {order_status == "Request For Cancel"?  <div className="text-center text-success mt-3">
                                        <h5>Your Cancel Request is Submited Successfully</h5>
                                      </div>:null} */}
                    </div>
                  </div>

                  <div className="row d-flex justify-content-between">
                    <div className="col-sm-2">
                      <span className="btn btn-success fw-normal fs-6 p-1 mr-2">Delivered</span>
                    </div>
                    <div className="col-sm-3">
                      <span className="btn btn-outline-success fw-normal p-1">Track Shipment</span>
                    </div>
                    <div className="col-sm-3"></div>

                    {/* <div className="col-sm-4">
                      <Button size="sm" className="btn-dim" style={{ width: "82%", height: "100%" }} color="dark">
                        Download Invoice &nbsp;<Icon name="arrow-to-down"></Icon>
                      </Button>
                    </div> */}
                  </div>

                  <div className="alert alert-success p-1 mt-2 mb-2">Delivered on {deliverDt}</div>
                </div>

                <div className="row p-0">
                  <div className="col-md-12 d-flex justify-content-between mb-2">
                    <div className="prod-com d-flex ">
                      <div className="images1">
                        <img
                          src={`${API_Image}/${ProductImage}`}
                          className="border-radius-50 imagprod border mr-2"
                        ></img>
                      </div>

                      <div>
                        <h5 className="mb-1 fs-6">{productname ? productname.substring(0, 20) : null}...</h5>
                        <h6 className="mb-1 fs-6">Quantity : {qty}</h6>
                        <span>Price : INR {saleprice}</span>
                        <span>SKU : {SKU}</span>
                        <span>HSN Code: {HSN}</span>
                        <span>EAN: {EAN}</span>
                      </div>
                    </div>
                    <div className="gst-com pr-4 pt-3">
                      <span className="d-block">Total : ₹{total}</span>
                      <span className="d-block">18% GST : ₹61.63 </span>
                      <span className="d-block">12% GST : </span>
                    </div>
                  </div>

                  <div className="col-md-4  mb-3">
                    <div className="">
                      <select
                        name=""
                        value={values ? values : Warehouse_id}
                        onChange={(e) => handleChangewarehouse(e)}
                        className="form-control form-select"
                        id="fv-topics"
                        disabled={order_status == "New orders" || order_status == "New Orders" ? false : true}
                        placeholder="Select a option"
                      // style={{ width: "150px" }}
                      >
                        {Warehouse.length > 0 ? (
                          <>
                            {Warehouse.map((currEle) => {
                              return (
                                <>
                                  <option label={currEle.warehouse_name} value={currEle.warehouse_id}></option>
                                </>
                              );
                            })}
                          </>
                        ) : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="">
                      <RSelect
                        options={[
                          { value: "New orders", label: "New orders" },
                          { value: "Preparing", label: "Preparing" },
                          { value: "Ready to Handover", label: "Ready to Handover" },
                          { value: "Shipped", label: "Shipped" },
                          { value: "Completed", label: "Completed" },
                          { value: "RTO In-transit", label: "RTO In-transit" },
                          { value: "Returned", label: "Returned" },
                          { value: "Refunded", label: "Refunded" },
                          // { value: "Partially refunded", label: "Partially refunded" },
                          { value: "Lost (AWB)", label: "Lost (AWB)" },
                          { value: "Cancelled", label: "Cancelled" },
                          // { value: "Upcoming orders", label: "Upcoming orders" },
                        ]}
                        value={isSPMethod}
                        onChange={(e) => handleChangeShip(e)}
                      />
                    </div>
                  </div>
                  <div>
                    <span className="btn btn-danger p-1 mr-5" type="button" onClick={() => handleSubmits()}>
                      Submit
                    </span>
                  </div>
                  {/* <div className="col-md-12 d-flex justify-content-between">
                    <div className="prod-com d-flex ">
                      <div className="images1">
                        <img src={cream} className="border-radius-50 imagprod border mr-2"></img>
                      </div>

                      <div>
                        <h5 className="mb-1 fs-6">Cream & Powders Cream & Powders</h5>
                        <h6 className="mb-1 fs-6">Quantity : 15</h6>
                        <span>Price : INR 1400</span>
                        <span>Sku : ORAJ_003</span>
                        <span>HSN Code: 33061020</span>
                        <span>EAN: 310310339739</span>
                      </div>
                    </div>
                    <div className="gst-com pr-4 pt-3">
                      <span className="d-block">Total : ₹342.37</span>
                      <span className="d-block">18% GST :  ₹61.63	</span>
                      <span className="d-block">12% GST : </span>
                    </div>
                  </div> */}

                  {/* <div className="col-md-12 d-flex mb-3">

                    <div className="mr-3">
                      <select
                        name=""
                        
                        className="form-control form-select"
                        id="fv-topics"
                        placeholder="Select a option"
                        style={{ width: "150px" }}
                      >
                        <option label="Select" value=""></option>
                        <option label="Select.." value=""></option>

                      </select>

                    </div>

                    <div>
                      <span className="btn btn-danger p-1 mr-5">Submit</span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </>

          <div>
            <hr />
          </div>

          <div>{/* <hr /> */}</div>
          {/*order end*/}

          <div className="row d-none">
            <div className="col-md-12" style={{ overflow: "scroll" }}>
              {/* <Invoice /> */}
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">IMAGE</th>
                    <th scope="col">PRODUCT NAME</th>
                    <th scope="col">SKU</th>
                    <th scope="col">EAN</th>
                    <th scope="col">VENDOR NAME</th>
                    <th scope="col">EXPIRY</th>
                    <th scope="col">ATTRIBUTES</th>
                    <th scope="col">QTY</th>
                    <th scope="col">WAREHOUSE NAME</th>
                    <th scope="col">REGULAR PRICE</th>
                    <th scope="col">SOLD PRICE</th>
                    <th scope="col">GST PRICE</th>
                    <th scope="col">TRACKING NUMBER</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <td>Cream & Powders</td>
                    <td>NV-010</td>
                    <td>123567891-NV</td>
                    <td>Navin</td>
                    <td>Aug 2023</td>
                    <td>250g</td>
                    <td>2</td>
                    <td>Guindy</td>
                    <td>INR 1200</td>
                    <td>INR 1100</td>
                    <td>INR 100</td>

                    <td>BA-001 </td>
                    <td>
                      <Badge color="primary">Delivered</Badge>{" "}
                    </td>
                    <td>INR 1400</td>
                  </tr>
                  <tr>
                    <th>
                      <img
                        src={diapers}
                        className="border-radius-50"
                        style={{ borderRadius: "50%", width: "50%", height: "50%" }}
                      ></img>
                    </th>
                    <td>Diapers & Pants</td>
                    <td>PM-010</td>
                    <td>123567891-PM</td>
                    <td> kumar</td>
                    <td>Aug 2022</td>
                    <td>sm -6 nos</td>
                    <td>1</td>
                    <td>vadapalani</td>
                    <td>INR 1400</td>
                    <td>INR 1340</td>
                    <td>INR 150</td>
                    <td> BA-004</td>
                    <td>
                      <Badge color="primary">Shipping</Badge>{" "}
                    </td>
                    <td>INR 770.53</td>
                  </tr>
                  <tr>
                    <th>
                      <img
                        src={nappycleaning}
                        className="border-radius-50"
                        style={{ borderRadius: "50%", width: "50%", height: "50%" }}
                      ></img>
                    </th>
                    <td>Nappy Cleaning</td>
                    <td>NC-010</td>
                    <td>123567891-NC</td>
                    <td>Navin</td>
                    <td>Feb 2022</td>
                    <td>- 3 nos</td>
                    <td>1</td>
                    <td>Guindy</td>
                    <td>INR 1800</td>
                    <td>INR 1600</td>
                    <td>INR 250</td>

                    <td> BA-005 </td>
                    <td>
                      <Badge color="primary">Delivered</Badge>{" "}
                    </td>
                    <td>INR 770.53</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Cards;
